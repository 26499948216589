export const isProductAvailable = (product) => {
  if (!product.restaurant || product.restaurant.length === 0) {
    return false;
  }

  const utcNow = new Date();
  const currentTime = new Date(utcNow.toLocaleString("en-US", { timeZone: "Asia/Krasnoyarsk" }));

  const currentHours = currentTime.getHours();
  const currentMinutes = currentTime.getMinutes();

  const currentTimeInMinutes = currentHours * 60 + currentMinutes;

  // console.log(`Текущее время: ${currentHours}:${currentMinutes}`);

  for (const restaurant of product.restaurant) {
    const [fromHours, fromMinutes] = restaurant.timeFrom.split(':').map(Number);
    const [toHours, toMinutes] = restaurant.timeTo.split(':').map(Number);

    const fromTimeInMinutes = fromHours * 60 + fromMinutes;
    const toTimeInMinutes = toHours * 60 + toMinutes;

    // console.log(`Время открытия: ${fromHours}:${fromMinutes}, Время закрытия: ${toHours}:${toMinutes}`);

    if (fromTimeInMinutes > toTimeInMinutes) {
      if (
        (currentTimeInMinutes >= fromTimeInMinutes && currentTimeInMinutes < 24 * 60) || 
        (currentTimeInMinutes >= 0 && currentTimeInMinutes <= toTimeInMinutes)
      ) {
        return true;
      }
    } else {
      if (currentTimeInMinutes >= fromTimeInMinutes && currentTimeInMinutes <= toTimeInMinutes) {
        return true;
      }
    }
  }

  return false;
};
