/* src/components/CategoryContainer.js */

import React, { useState, useEffect } from 'react';
import { getCategories, getMenuItems, addToCart } from '../services/api';
import Loader from './Loader';
import ProductDetailsModal from './ProductDetailsModal';
import './CategoryContainer.css';
import { ReactComponent as RestaurantIcon } from './restaurant-icon.svg';
import { ReactComponent as DeliveryIcon } from './delivery-icon.svg';
import { isProductAvailable } from '../utils/timeUtils';

const CategoryContainer = ({ selectedCategory, setSelectedCategory, onAddToCart, cart, onRemoveFromCart }) => {
  const [categories, setCategories] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedVariants, setSelectedVariants] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [currentVariant, setCurrentVariant] = useState(null);
  const [showRestaurants, setShowRestaurants] = useState(false);
  const [showDeliveryModal, setShowDeliveryModal] = useState(false); // Состояние для отображения модального окна доставки
  const telegram = window.Telegram.WebApp;
  const user = telegram.initDataUnsafe.user;
  const authToken = telegram.initData;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [categoriesResponse, menuResponse] = await Promise.all([getCategories(user.id), getMenuItems(user.id)]);
        setCategories(categoriesResponse.data.category);
        setAllProducts(menuResponse.data.menu);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      setLoading(true);
      const subCategories = categories.filter(
        category => category.parentMenuCategoryId === selectedCategory && category.level === 1
      );

      const categoryIds = subCategories.map(subCategory => subCategory.menuCategoryId);
      categoryIds.push(selectedCategory); // Добавляем основную категорию
  
      const filteredProducts = allProducts.flatMap(menu =>
        menu.Products.filter(product =>
          (selectedSubCategory
            ? product.menuCategoryId === selectedSubCategory
            : categoryIds.includes(product.menuCategoryId)) &&
          !product.isHideInApp
        )
      );
  
      // Сортируем товары по порядку категорий
      const sortedProducts = filteredProducts.sort((a, b) => {
        const categoryIndexA = categoryIds.indexOf(a.menuCategoryId);
        const categoryIndexB = categoryIds.indexOf(b.menuCategoryId);
        return categoryIndexA - categoryIndexB; // Сортируем на основе индекса категории
      });

      setItems(sortedProducts);
      setLoading(false);
    } else {
      setItems([]);
    }
  }, [selectedCategory, selectedSubCategory, categories, allProducts]);

  useEffect(() => {
    if (!selectedCategory) {
      setSelectedSubCategory(null);
      setSelectedVariants({});
    }
  }, [selectedCategory]);

  const getCartItemQuantity = (itemId) => {
    const cartItem = cart?.find(item => item.card_id === itemId);
    return cartItem ? cartItem.quantity : 0;
  };

  const handleVariantSelect = (productId, variant) => {
    setSelectedVariants(prev => ({ ...prev, [productId]: variant }));
  };

  const handleProductClick = (product, variant) => {
    setCurrentProduct(product);
    setCurrentVariant(variant);
    setShowModal(true);
  };

  const handleShowRestaurants = (product) => {
    setCurrentProduct(product);
    setShowRestaurants(true);
  };

  const handleShowDeliveryModal = () => {
    setShowDeliveryModal(true);
  };

  const handleAddToCart = (product, variant) => {
    // Получаем обязательные модификаторы для товара
    const getDefaultOptions = (product, variant) => {
      const defaultOptions = [];
      
      // Если есть вариант, добавляем его модификаторы
      if (variant) {
        defaultOptions.push(...variant.optionIds);
      }

      // Добавляем обязательные модификаторы из категорий
      product.optionCategories?.forEach(optionCategory => {
        // Проверяем ограничения категории
        const restrictions = optionCategory.restrictions || {};
        const min = restrictions.min || 0;

        // Если есть обязательные модификаторы (min > 0)
        if (min > 0) {
          let hasDefaultOption = false;
          optionCategory.options.some(option => {
            if (option.restrictions?.isDefault && !hasDefaultOption) {
              defaultOptions.push(option.optionId);
              hasDefaultOption = true;
              return true;
            }
            return false;
          });

          // Если нет модификатора по умолчанию, берем первый доступный
          if (!hasDefaultOption && min > 0) {
            defaultOptions.push(optionCategory.options[0].optionId);
          }
        }
      });

      return Array.from(new Set(defaultOptions)); // Убираем дубликаты
    };

    const modifierIds = getDefaultOptions(product, variant);

    const productToAdd = {
      products: [
        {
          modifierIds,
          productId: product.productId,
          comment: "",
          amount: 1,
          isExcise: product.isExcise || false,
        },
      ],
      combos: []
    };

    addToCart(productToAdd);
    onAddToCart();
  };

  return (
    <div id="category-container">
      {loading ? (
        <Loader />
      ) : !selectedCategory ? (
        categories
          .filter(category => category.level === 0)
          .map(category => (
            <div
              key={category.menuCategoryId}
              className="card"
              onClick={() => setSelectedCategory(category.menuCategoryId)}
            >
              <img src={`https://novosibirsk.grilnica.ru/cdn/${category.imageFileName}`} alt={category.name} />
              <h3>{category.name}</h3>
            </div>
          ))
      ) : (
        <>
          {categories.some(category => category.parentMenuCategoryId === selectedCategory && category.level === 1) && (
            <div className="subcategory-buttons">
              <button
                onClick={() => setSelectedSubCategory(null)}
                className={!selectedSubCategory ? 'active' : ''}
              >
                Все
              </button>
              {categories
                .filter(category => category.parentMenuCategoryId === selectedCategory && category.level === 1)
                .map(subCategory => (
                  <button
                    key={subCategory.menuCategoryId}
                    onClick={() => setSelectedSubCategory(subCategory.menuCategoryId)}
                    className={selectedSubCategory === subCategory.menuCategoryId ? 'active' : ''}
                  >
                    {subCategory.name}
                  </button>
                ))}
            </div>
          )}

          {items.length > 0 ? (
            items.filter(item => (item.price > 0 || (item.productVariants && item.productVariants.some(variant => variant.price > 0)))).map(item => {
              const quantity = getCartItemQuantity(item.productId);
              const hasVariants = item.productVariants && item.productVariants.length > 0;
              const selectedVariant = selectedVariants[item.productId] || (hasVariants ? item.productVariants[0] : null);
              const isAvailable = isProductAvailable(item);

              return (
                <div 
                  key={item.productId} 
                  className={`card ${!isAvailable ? 'disabled' : ''}`}
                  onClick={() => isAvailable && handleProductClick(item, selectedVariant)}
                >
                  <div className="product-image-container">
                    <img 
                      src={`https://novosibirsk.grilnica.ru/cdn/${item.imageFileName}`} 
                      alt={item.name} 
                      className={!isAvailable ? 'image-disabled' : ''}
                    />
                    <div className="product-labels">
                      {item.labels?.map(label => (
                        <img 
                          key={label.labelId} 
                          src={`https://novosibirsk.grilnica.ru/cdn/${label.iconFileName}`} 
                          alt={label.name} 
                          className="product-label-icon"
                        />
                      ))}
                    </div>
                    {item.restaurant && (
                      <RestaurantIcon 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleShowRestaurants(item);
                        }} 
                        className="restaurant-icon"
                      />
                    )}
                    {item.isDeliveryDeny && (
                      <DeliveryIcon 
                        onClick={(e) => {
                          e.stopPropagation();
                          handleShowDeliveryModal();
                        }} 
                        className="delivery-icon"
                      />
                    )}
                  </div>
                  <h3>{item.name}</h3>
                  <p>{item.description}</p>
                  <button 
                    className="add-to-cart-button" 
                    disabled={!isAvailable}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (isAvailable) {
                        if (item.type === "COMBO") {
                          handleProductClick(item, selectedVariant);
                        } else {
                          handleAddToCart(item, selectedVariant);
                        }
                      }
                    }}
                  >
                    {isAvailable ? 'Добавить в корзину ' : 'Ресторан еще закрыт '}
                    {selectedVariant ? (
                      selectedVariant.salePrice && selectedVariant.salePrice !== 0 ? (
                        <>
                          <span style={{ textDecoration: 'line-through' }}>{selectedVariant.price} ₽</span> 
                          <span> {selectedVariant.salePrice} ₽</span>
                        </>
                      ) : (
                        <span>{selectedVariant.price} ₽</span>
                      )
                    ) : (
                      item.salePrice && item.salePrice !== 0 ? (
                        <>
                          <span style={{ textDecoration: 'line-through' }}>{item.price} ₽</span> 
                          <span> {item.salePrice} ₽</span>
                        </>
                      ) : (
                        <span>{item.price} ₽</span>
                      )
                    )}
                  </button>
                </div>
              );
            })
          ) : (
            <p>Товары не найдены.</p>
          )}
        </>
      )}

      {showModal && currentProduct && (
        <ProductDetailsModal
          product={currentProduct}
          selectedVariant={currentVariant}
          onClose={() => setShowModal(false)}
          onAddToCart={(productToAdd) => {
            addToCart(productToAdd);
            onAddToCart();
            setShowModal(false);
          }}
        />
      )}

      {/* Модальное окно для отображения ресторанов */}
      {showRestaurants && currentProduct && (
        <div className="restaurant-modal">
          <h3>Доступно по адресу:</h3>
          <ul>
            {currentProduct.restaurant.map(restaurant => (
              <li key={restaurant.restaurantId}>
                {restaurant.address} <br/>
                {restaurant.timeIntervalsString}
              </li>
            ))}
          </ul>
          <button onClick={() => setShowRestaurants(false)}>OK</button>
        </div>
      )}

      {showDeliveryModal && (
        <div className="delivery-modal">
          <h3>Данный товар недоступен для доставки, но вы можете забрать его самостоятельно</h3>
          <button onClick={() => setShowDeliveryModal(false)}>OK</button>
        </div>
      )}
    </div>
  );
};

export default CategoryContainer;
