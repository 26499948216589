import React, { useEffect, useState } from 'react';
import { createOrder, getUserCart, getMenuItems, removeFromCart } from '../services/api';
import { ReactComponent as TrashIcon } from './trash.svg';
import Loader from './CartLoader';
import { isProductAvailable } from '../utils/timeUtils';

const CartSidebar = ({ isOpen, setIsOpen, userId, fetchUserCart }) => {
  const [cart, setCart] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [unavailableItems, setUnavailableItems] = useState([]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll'); // Блокируем прокрутку
    } else {
      document.body.classList.remove('no-scroll'); // Возвращаем прокрутку
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen]);

  useEffect(() => {
    if (userId && isOpen) {
      fetchMenuAndCart();
    }
  }, [userId, isOpen]);

  const fetchMenuAndCart = async () => {
    try {
      setLoading(true);
      const [menuResponse, cartResponse] = await Promise.all([getMenuItems(userId), getUserCart(userId)]);
      const menuData = menuResponse.data.menu.flatMap(category => category.Products);
      const cartData = cartResponse.data;

      setMenuItems(menuData);

      const updatedCart = [];
      const unavailableItemsList = [];

      if (cartData.products && cartData.products.length > 0) {
        const productItems = cartData.products.map(cartItem => {
          const product = menuData.find(item => item.productId === cartItem.productId);

          if (product) {
            if (!isProductAvailable(product)) {
              unavailableItemsList.push(cartItem);
              return null;
            }

            let productPrice = product.price || 0;
            let variant = null;

            if (product.productVariants) {
              variant = product.productVariants.find(v =>
                v.optionIds.some(optionId => cartItem.modifierIds.includes(optionId))
              );
              if (variant) {
                productPrice = variant.salePrice && variant.salePrice > 0
                  ? variant.salePrice
                  : variant.price;
              }
            }

            const modifiers = cartItem.modifierIds.map(modifierId => {
              return product.optionCategories
                ?.flatMap(category => category.options)
                ?.find(option => option.optionId === modifierId) || {};
            }).filter(Boolean);

            const modifiersPrice = modifiers.reduce((total, modifier) => total + (modifier.price || 0), 0);
            const totalPrice = productPrice + modifiersPrice;

            return {
              ...product,
              ...cartItem,
              modifiers,
              variant,
              totalPrice,
            };
          } else {
            unavailableItemsList.push(cartItem);
            return null;
          }
        }).filter(Boolean);

        updatedCart.push(...productItems);
      }

      if (cartData.combos && cartData.combos.length > 0) {
        const comboItems = cartData.combos.map(combo => {
          const firstProduct = combo.products[0];
          const product = menuData.find(item => item.productId === firstProduct.productId);
          
          if (product && !isProductAvailable(product)) {
            unavailableItemsList.push(combo);
            return null;
          }

          const productImage = product?.imageFileName || 'placeholder.jpg';

          return {
            ...combo,
            imageFileName: productImage,
            totalPrice: combo.price || 0,
          };
        }).filter(Boolean);

        updatedCart.push(...comboItems);
      }

      setCart(updatedCart);
      setUnavailableItems(unavailableItemsList);
      setLoading(false);

      if (unavailableItemsList.length > 0) {
        handleRemoveUnavailableItems(unavailableItemsList);
      }

    } catch (error) {
      console.error('Error fetching menu and cart:', error);
      setLoading(false);
    }
  };

  const handleRemoveUnavailableItems = async (items) => {
    const promises = items.map(item => 
      removeFromCart(userId, item.orderproductId || item.productID, 1)
        .catch(error => {
          console.error('Error removing unavailable item from cart:', error);
        })
    );
    
    await Promise.all(promises);
    alert('Некоторые товары были удалены из корзины, так как они сейчас недоступны для заказа.');
    fetchUserCart(userId);
  };

  const handleRemove = (orderproductId, isCombo) => {
    if (!userId) {
      console.error('User ID is not available');
      return;
    }

    removeFromCart(userId, orderproductId, 1)
      .then(() => {
        setCart(prevCart => {
          if (isCombo) {
            return prevCart.filter(cartItem => cartItem.productID !== orderproductId);
          } else {
            return prevCart.filter(cartItem => cartItem.orderproductId !== orderproductId);
          }
        });
        fetchUserCart(userId);
      })
      .catch(error => {
        console.error('Error removing item from cart:', error);
      });
  };

  const handleOrderSubmit = () => {
    if (!userId) {
      console.error('User ID is not available');
      return;
    }

    createOrder(userId)
      .then((response) => {
        if (response.status === 200) {
          setIsOpen(false);
          window.Telegram.WebApp.close();
        }
      })
      .catch(error => {
        console.error('Error creating order:', error);
        window.Telegram.WebApp.close();
      });
  };

  const calculateTotalSum = () => {
    return cart.reduce((sum, item) => {
      const basePrice = item.variant
        ? (item.variant.salePrice && item.variant.salePrice > 0 ? item.variant.salePrice : item.variant.price)
        : (item.salePrice && item.salePrice > 0 ? item.salePrice : item.price);

      const modifiersPrice = (item.modifiers || []).reduce((total, modifier) => total + (modifier.price || 0), 0);
      return sum + basePrice + modifiersPrice;
    }, 0);
  };

  return (
    <>
      {isOpen && (
        <div
          className="overlay"
          onClick={() => setIsOpen(false)}
        />
      )}
      <div id="cart-sidebar" className={`cart-sidebar ${isOpen ? 'open' : ''}`}>
        <div className="cart-header">
          <h2>Корзина</h2>
          <button id="close-cart" className="close-cart" onClick={() => setIsOpen(false)}>Закрыть</button>
        </div>
        {isLoading && (<Loader />)}
        {!isLoading && (
          <div className="card-content">
            <div id="cart-items" className="cart-items">
              {cart.length > 0 ? (
                cart.map((item, index) => (
                  <div key={index} className="cart-item">
                    <img
                      className="cart-item-image"
                      src={`https://novosibirsk.grilnica.ru/cdn/${item.imageFileName}`}
                      alt={item.name || 'Товар'}
                    />
                    <div className="cart-item-details">
                      <h3 className="cart-item-name">{item.name}</h3>
                      <p className="cart-item-price">{item.totalPrice} ₽</p>
                    </div>
                    <div className="cart-item-trash-icon">
                      <TrashIcon
                        style={{ width: "32px", height: "32px" }}
                        onClick={() => handleRemove(item.productID || item.orderproductId, !!item.products)}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <p>Корзина пуста</p>
              )}
            </div>
            <div className="cart-footer">
              <p id="total-sum">Итого: {calculateTotalSum()} ₽</p>
              <button className="order-button" disabled={cart.length === 0} onClick={handleOrderSubmit}>Оформить заказ</button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CartSidebar;
