import React, { useState, useEffect } from 'react';
import './ProductDetailsModal.css'; // Стили для модального окна
import ComboProductDetails from './ComboProductDetails'; // Импортируем компонент для отображения товаров комбо
import { ReactComponent as CloseIcon } from './cross.svg';
import { fetchWeightMap } from '../services/api'; // Импортируем функцию для получения карты весов

const ProductDetailsModal = ({ product, onClose, onAddToCart, hideAddToCartButton }) => {
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [categoryRestrictions, setCategoryRestrictions] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0); // Добавляем состояние для веса
  const [selectedCombos, setSelectedCombos] = useState([]);
  const [weightMap, setWeightMap] = useState({}); // Состояние для карты весов

  useEffect(() => {
    // Блокируем скроллинг фона при открытии модального окна
    document.body.style.overflow = 'hidden';
  
    return () => {
      // Возвращаем скроллинг фона при закрытии модального окна
      document.body.style.overflow = '';
    };
  }, []); // Пустой массив зависимостей, чтобы эффект срабатывал один раз при монтировании и размонтировании

  useEffect(() => {
    // Получаем карту весов при монтировании компонента
    const loadWeightMap = async () => {
      try {
        const response = await fetchWeightMap();
        setWeightMap(response.data); // Сохраняем карту весов
      } catch (error) {
        console.error('Ошибка загрузки карты весов:', error);
      }
    };

    loadWeightMap();
  }, []);

  useEffect(() => {
    if (weightMap && selectedOptions.length > 0) {
      const newWeight = getOptionsWeight(selectedOptions, weightMap, product, selectedVariant);
      setTotalWeight((newWeight || 0).toFixed(2));
    } else if (weightMap) {
      const newWeight = getOptionsWeight([], weightMap, product, selectedVariant);
      setTotalWeight((newWeight || 0).toFixed(2));
    }
  }, [selectedOptions, weightMap, product, selectedVariant]);

  useEffect(() => {
    if (product.productVariants && product.productVariants.length > 0) {
      // Ищем вариант, который выбран по умолчанию (может быть любой, а не первый)
      const defaultVariant = product.productVariants.find(variant => 
        variant.optionIds.some(optionId => 
          product.optionCategories.some(category =>
            category.options.some(option => option.optionId === optionId && option.restrictions?.isDefault)
          )
        )
      ) || product.productVariants[0]; // Если нет, выбираем первый
  
      setSelectedVariant(defaultVariant);
      setSelectedOptions(defaultVariant.optionIds); // Устанавливаем модификаторы для варианта
      updateCategoryRestrictions(defaultVariant);
  
      // Проверка акционной цены для варианта
      const priceToSet = defaultVariant.salePrice && defaultVariant.salePrice > 0 
        ? defaultVariant.salePrice 
        : defaultVariant.price;
      setTotalPrice(priceToSet);
    } else if (product.optionCategories) {
      const defaultRestrictions = {};
      product.optionCategories.forEach(optionCategory => {
        const { min, max } = getOptionRestrictions(optionCategory);
        defaultRestrictions[optionCategory.optionCategoryId] = { min, max };
      });
      setCategoryRestrictions(defaultRestrictions);
  
      // Проверка акционной цены для товара без вариантов
      const priceToSet = product.salePrice && product.salePrice > 0 
        ? product.salePrice 
        : product.price;
      setTotalPrice(priceToSet);
    } else {
      // Если нет категорий опций, проверяем акционную цену для товара
      const priceToSet = product.salePrice && product.salePrice > 0 
        ? product.salePrice 
        : product.price;
      setTotalPrice(priceToSet);
    }
  
    // Логика для модификаторов по умолчанию
    const defaultOptions = [];
    product.optionCategories?.forEach(optionCategory => {
      let hasDefaultOption = false;
      optionCategory.options.some(option => {
        if (option.restrictions && option.restrictions.isDefault && !hasDefaultOption) {
          defaultOptions.push(option.optionId);
          hasDefaultOption = true;
          return true;
        }
        return false;
      });
    });
  
    setSelectedOptions(prevOptions => {
      const combinedOptions = [...prevOptions, ...defaultOptions];
      const uniqueOptions = combinedOptions.filter((option, index) => combinedOptions.indexOf(option) === index);
      return uniqueOptions;
    });
  }, [product]);

  useEffect(() => {
    calculateTotalPrice();
  }, [selectedVariant, selectedOptions]);

  const updateCategoryRestrictions = (variant) => {
    const newRestrictions = {};

    product.optionCategories?.forEach(optionCategory => {
      const { min, max } = getOptionRestrictions(optionCategory, variant);
      newRestrictions[optionCategory.optionCategoryId] = { min, max };
    });

    setCategoryRestrictions(newRestrictions);
  };

  const getOptionRestrictions = (optionCategory, variant = selectedVariant) => {
    if (optionCategory.restrictions && optionCategory.restrictions.min !== null && optionCategory.restrictions.max !== null) {
      return {
        min: optionCategory.restrictions.min,
        max: optionCategory.restrictions.max,
      };
    } else if (variant && optionCategory.productVariantRestrictions) {
      const variantRestrictions = optionCategory.productVariantRestrictions.productVariantId[variant.productVariantId];
      if (variantRestrictions) {
        return {
          min: variantRestrictions.min !== undefined ? variantRestrictions.min : 1,
          max: variantRestrictions.max !== undefined ? variantRestrictions.max : 10,
        };
      }
    }
    return { min: 0, max: 10 };
  };

  const calculateTotalPrice = () => {
    // Проверяем акционную цену выбранного варианта или самого товара
    let price = selectedVariant 
      ? (selectedVariant.salePrice && selectedVariant.salePrice > 0 ? selectedVariant.salePrice : selectedVariant.price) 
      : (product.salePrice && product.salePrice > 0 ? product.salePrice : product.price);

    selectedOptions.forEach(optionId => {
      const option = product.optionCategories?.flatMap(category => category.options).find(option => option.optionId === optionId);
      if (option && option.price) {
        price += option.price;
      }
    });

    setTotalPrice(price);
  };

  function getOptionsWeight(optionIds, weightMap, product, selectedVariant = null) {
    if (!optionIds || !weightMap || !product) {
      return undefined;
    }
  
    let resultWeight = 0;
  
    // Если у товара есть варианты и выбран вариант, используем его вес
    if (selectedVariant && selectedVariant.weight) {
      resultWeight += selectedVariant.weight;
    } else if (product.weight) {
      // Если у товара нет вариантов, используем вес самого продукта
      resultWeight += product.weight;
    }
  
    // Проходим по всем выбранным модификаторам и добавляем их вес
    for (const optionId of optionIds) {
      let optionWeight;
      const weights = weightMap[optionId];
  
      if (weights) {
        // Проверяем зависимость веса от других модификаторов (dependsOnObjectId)
        for (const weight of weights) {
          if (optionIds.includes(weight.dependsOnObjectId)) {
            optionWeight = weight.weight;
            break;
          }
        }
  
        // Если не нашли зависимости, берем вес без зависимости (dependsOnObjectId: null)
        if (optionWeight === undefined) {
          optionWeight = weights.find((w) => !w.dependsOnObjectId)?.weight;
        }
  
        // Добавляем вес модификатора
        resultWeight += optionWeight ?? 0;
      }
    }
  
    return resultWeight;
  }

  const handleOptionSelect = (optionId, max, min, optionCategoryId) => {
    const categoryOptions = product.optionCategories.find(category => category.optionCategoryId === optionCategoryId)?.options || [];
    const selectedInCategory = selectedOptions.filter(id => categoryOptions.some(option => option.optionId === id));
  
    // Условие: блокируем только если min != 0 и (max != 0 или 1)
    if ((min !== 0 && (max !== 0 && max !== 1)) && selectedInCategory.length >= max && !selectedInCategory.includes(optionId)) {
      return; // Если достигнут максимум, запрещаем выбор новых опций только при этих условиях
    }
    // Проверяем, является ли опция вариантом товара (например, бортик)
    const isVariantOption = product.productVariants?.some(variant => variant.optionIds.includes(optionId));
  
    // Если это вариант товара
    if (isVariantOption) {
      handleVariantSelect(product.productVariants.find(variant => variant.optionIds.includes(optionId)));
      return; // Выходим, так как обработка варианта завершена
    }
  
    // Логика для min = 1 и max = 1, а также для min = 0 и max = 1
    if ((min === 1 && max === 1) || (min === 0 && max === 1)) {
      if (selectedInCategory.includes(optionId)) {
        if (min === 0) {
          // Убираем выбранную опцию, если min = 0 (разрешаем "отжатие")
          setSelectedOptions(selectedOptions.filter(id => id !== optionId));
        }
        return; // Не даём "отжать" модификатор, если min = 1
      }
  
      // Убираем старые опции и выбираем новую
      const newSelected = selectedOptions.filter(id => !categoryOptions.some(option => option.optionId === id));
      setSelectedOptions([...newSelected, optionId]);
    } else {
      // Логика для категорий с другими ограничениями
      if (selectedOptions.includes(optionId)) {
        setSelectedOptions(selectedOptions.filter(id => id !== optionId)); // Убираем, если уже выбрано
      } else if (selectedInCategory.length < max) {
        setSelectedOptions([...selectedOptions, optionId]); // Добавляем новую опцию
      }
    }
  
    // Логика для предотвращения выбора одинаковых опций
    let currentSelectedModifiers = selectedOptions
      .map(selectedId => product.optionCategories.flatMap(cat => cat.options).find(option => option.optionId === selectedId))
      .filter(Boolean);
  
    const selectedOption = product.optionCategories
      .flatMap(cat => cat.options)
      .find(option => option.optionId === optionId);
  
    if (!selectedOption) return;
  
    // Проверяем, есть ли модификаторы с таким же именем в других категориях
    const conflictingOption = currentSelectedModifiers
      .find(option => option.name === selectedOption.name && option.optionCategoryId !== optionCategoryId);
  
    if (conflictingOption) {
      setSelectedOptions(prevOptions => prevOptions.filter(id => id !== conflictingOption.optionId));
    }
  
    // Перемещаем модификатор варианта товара в начало массива
    const variantOptionIds = selectedVariant?.optionIds || [];
    setSelectedOptions(prevOptions => {
      const nonVariantOptions = prevOptions.filter(id => !variantOptionIds.includes(id));
      return [...variantOptionIds, ...nonVariantOptions];
    });
  };
  

  const handleVariantSelect = (variant) => {
    if (!variant) return;
    setSelectedVariant(variant);
    updateCategoryRestrictions(variant);
    filterExcessOptions(variant);
    setSelectedOptions(prevOptions => {
      const optionsWithoutOldVariant = prevOptions.filter(optionId => !selectedVariant?.optionIds.includes(optionId));
      const updatedOptions = [...variant.optionIds, ...optionsWithoutOldVariant];
      return Array.from(new Set(updatedOptions)); // Убираем дубли
    });
  };

  const filterExcessOptions = (variant) => {
    const updatedOptions = [...selectedOptions];

    product.optionCategories?.forEach(optionCategory => {
      const { max } = getOptionRestrictions(optionCategory, variant);

      const categoryOptions = optionCategory.options.map(option => option.optionId);
      const selectedInCategory = updatedOptions.filter(optionId => categoryOptions.includes(optionId));

      while (selectedInCategory.length > max) {
        const optionToRemove = selectedInCategory.pop();
        updatedOptions.splice(updatedOptions.indexOf(optionToRemove), 1);
      }
    });

    setSelectedOptions(updatedOptions);
  };

  const handleComboChange = (combos) => {
    setSelectedCombos(combos);
  };

  const handleAddToCart = () => {
    // Если есть выбранные комбо, отправляем только комбо
    if (selectedCombos.length > 0) {
      const productToAdd = {
        products: [], // Товары не отправляем
        combos: selectedCombos.map(combo => ({
          ...combo,
          programId: product.productId, // Устанавливаем programId как айди текущего продукта
          name: product.name,
          price:product.price
        })) // Отправляем комбо в нужной структуре
      };
      onAddToCart(productToAdd); 
    } else {
      // Если комбо не выбраны, отправляем обычный товар
      const allCategoriesValid = product.optionCategories?.every(optionCategory => {
        const selectedInCategory = selectedOptions.filter(optionId =>
          optionCategory.options.some(option => option.optionId === optionId)
        );

        const { min, max } = categoryRestrictions[optionCategory.optionCategoryId] || { min: 0, max: 10 };
        const count = selectedInCategory.length;
        return count >= min && count <= max;
      });

      if (allCategoriesValid || !product.optionCategories) {
        const productToAdd = {
          products: [
            {
              modifierIds: selectedOptions,
              productId: product.productId,
              comment: "", // можете добавить поле для комментариев, если нужно
              amount: 1, // или измените на нужное значение количества
              isExcise: product.isExcise || false, // или установите по умолчанию false
            },
          ],
          combos: [] // Комбо пусто, так как не выбрано
        };

        onAddToCart(productToAdd); 
      } else {
        alert('Пожалуйста, выберите допустимое количество опций в каждой категории.');
      }
    }
    onClose();
  };

  // Закрываем модальное окно при клике за его пределами
  const handleBackgroundClick = (e) => {
    if (e.target.classList.contains('modal')) {
      onClose();
    }
  };

  return (
    <div className="modal" onClick={handleBackgroundClick}>
      <div className="modal-content">
        {/* Добавляем SVG иконку крестика слева сверху */}
        <div className="modal-header">
          <CloseIcon className="close-icon" onClick={onClose} />
          <h2>{product.name}</h2>
        </div>
        <div className="product-image-container">
          <img 
              src={`https://novosibirsk.grilnica.ru/cdn/${product.imageFileName}`} 
            alt={product.name} 
          />
        </div>
        <p>{product.description}</p>
        <>
          {totalWeight > 0 && <p>Вес: {totalWeight} кг</p>}
        </>
        {product.comboGroupList && (
          <ComboProductDetails comboGroupList={product.comboGroupList} onComboChange={handleComboChange} />
        )}

        {product.optionCategories && (
          <div className="options-container">
            {product.optionCategories.map(optionCategory => {
              const { min, max } = categoryRestrictions[optionCategory.optionCategoryId] || { min: 0, max: 10 };
            
              return (
                <div key={optionCategory.optionCategoryId} className="option-category">
                  <h3>{optionCategory.name}</h3>
                  {/* <p>Выберите от {min} до {max} опций</p> */}
                  <div className="options">
                    {optionCategory.options.map(option => {
                      const selectedInCategory = selectedOptions.filter(id =>
                        optionCategory.options.some(opt => opt.optionId === id)
                      );
                      
                      // Условие: блокируем только если min !== 1 или max !== 1
                      const isDisabled = !(min === 1 && max === 1) && selectedInCategory.length >= max && !selectedOptions.includes(option.optionId);
                      
                      return (
                        <div
                          key={option.optionId}
                          className={`option ${selectedOptions.includes(option.optionId) ? 'selected' : ''} ${isDisabled ? 'disabled' : ''}`}
                          onClick={() => !isDisabled && handleOptionSelect(option.optionId, max, min, optionCategory.optionCategoryId)}
                        >
                          <p>{option.name}</p>
                          {option.price !== 0 && <p>{option.price} руб.</p>}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {/* Кнопка "Добавить в корзину" отображается только для основного товара, а не для товаров в комбо */}
        {!hideAddToCartButton && (
          <button className="add-to-cart-button" onClick={handleAddToCart}>
            {`Добавить в корзину за ${totalPrice} ₽.`}
          </button>
        )}
      </div>
    </div>
  );
};

export default ProductDetailsModal;
