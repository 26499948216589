import React, { useState, useEffect } from 'react';
import { getUserOrders, getOrderHistoryByUser, getMenuItems, submitOrderRating } from '../services/api';
import Loader from './Loader';
import './Orders.css';

const telegram = window.Telegram.WebApp;
const user = telegram.initDataUnsafe.user;

const Orders = ({ userId }) => {
  const [orders, setOrders] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [rating, setRating] = useState(0);
  const [ratingComment, setRatingComment] = useState('');
  const [isHistoryAvailable, setIsHistoryAvailable] = useState(true);

  useEffect(() => {
    if (userId) {
      fetchMenuAndOrders();
    }
  }, [userId]);

  const fetchMenuAndOrders = async () => {
    try {
      setLoading(true);
      const menuResponse = await getMenuItems(user.id);
      const menuData = menuResponse.data.menu.flatMap(category => category.Products);
      setMenuItems(menuData);

      try {
        const ordersResponse = await getUserOrders(userId);
        const updatedOrders = ordersResponse.data.map(order => ({
          ...order,
          products: order.products.map(product => enrichProductWithMenuData(product, menuData)),
          combos: order.combos.map(combo => enrichComboWithMenuData(combo, menuData)),
        }));
        setOrders(updatedOrders);
      } catch (error) {
        if (error.response?.status === 404) {
          console.warn('No current orders found.');
          setOrders([]);
        } else {
          console.error('Error fetching orders:', error);
        }
      }
    } catch (error) {
      console.error('Error fetching menu:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchOrderHistory = async () => {
    try {
      setLoading(true);
      const response = await getOrderHistoryByUser(userId);

      const updatedHistory = response.data.map(order => ({
        ...order,
        products: order.products?.length
          ? order.products.map(product => enrichProductWithMenuData(product, menuItems))
          : [],
        combos: order.combos?.length
          ? order.combos.map(combo => enrichComboWithMenuData(combo, menuItems))
          : [],
      }));
  
      setOrderHistory(updatedHistory);
      setShowHistory(true); // Показать секцию истории
    } catch (error) {
      console.error('Error fetching order history:', error);
  
      if (error.response?.status === 404) {
        setOrderHistory([]); // История пустая
        setShowHistory(true); // Показать секцию истории с сообщением
      } else {
        setShowHistory(false); // Скрыть историю при других ошибках
      }
    } finally {
      setLoading(false);
    }
  };

  const enrichProductWithMenuData = (product, menuData) => {
    const menuProduct = menuData.find(menuItem => menuItem.productId === product.productId);

    if (menuProduct) {
      let productPrice = menuProduct.price || 0;

      if (menuProduct.productVariants) {
        const selectedVariant = menuProduct.productVariants.find(variant =>
          variant.optionIds.some(optionId => product.modifierIds.includes(optionId))
        );
        if (selectedVariant) {
          productPrice = selectedVariant.price;
        }
      }

      const modifiers = product.modifierIds.map(modifierId => {
        return (
          menuProduct.optionCategories
            ?.flatMap(category => category.options)
            ?.find(option => option.optionId === modifierId) || {}
        );
      }).filter(Boolean);

      const modifiersPrice = modifiers.reduce((total, modifier) => total + (modifier.price || 0), 0);
      const totalPrice = productPrice + modifiersPrice;

      return {
        ...menuProduct,
        ...product,
        modifiers,
        totalPrice,
      };
    } else {
      return {
        ...product,
        name: 'Неизвестный товар',
        imageFileName: 'placeholder.jpg',
        totalPrice: 0,
      };
    }
  };

  const enrichComboWithMenuData = (combo, menuData) => {
    const menuCombo = menuData.find(menuItem => menuItem.productId === combo.programId);

    if (menuCombo) {
      return {
        ...menuCombo,
        ...combo,
        name: menuCombo.name || combo.name,
        price: menuCombo.price || combo.price,
        imageFileName: menuCombo.imageFileName || 'placeholder.jpg',
      };
    } else {
      return { ...combo, name: 'Неизвестное комбо', imageFileName: 'placeholder.jpg', price: 0 };
    }
  };

  const openRatingModal = (order) => {
    setCurrentOrder(order);
    setRating(order.rating || 0);
    setRatingComment(order.rating_comment || '');
    setIsRatingModalOpen(true);
  };

  const closeRatingModal = () => {
    setIsRatingModalOpen(false);
    setCurrentOrder(null);
    setRating(0);
    setRatingComment('');
  };

  const submitRating = async () => {
    try {
      await submitOrderRating(currentOrder.order_id, rating, ratingComment);
      const updatedOrders = orders.map(order =>
        order.id === currentOrder.id ? { ...order, rating, rating_comment: ratingComment } : order
      );
      setOrders(updatedOrders);

      const updatedHistory = orderHistory.map(order =>
        order.order_id === currentOrder.order_id ? { ...order, rating, rating_comment: ratingComment } : order
      );
      setOrderHistory(updatedHistory);

      closeRatingModal();
    } catch (error) {
      console.error('Error submitting rating:', error);
    }
  };

  const handleClose = () => {
    window.Telegram.WebApp.close();
  };

  const calculateOrderTotal = (order) => {
    const productsTotal = order.products.reduce((total, item) => total + (item.totalPrice * item.amount), 0);
    const combosTotal = order.combos.reduce((total, item) => total + (item.price * item.amount), 0);
    return productsTotal + combosTotal;
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString('ru-RU', options);
  };

  const formatPaymentType = (paymentType) => {
    switch (paymentType) {
      case 'payment_card':
        return 'Оплата картой';
      case 'payment_cash':
        return 'Оплата наличными';
      case 'payment_online':
        return 'Онлайн оплата';
      default:
        return 'Неизвестный тип оплаты';
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="orders-container">
      {/* <button className="close-button" onClick={handleClose}>Закрыть</button> */}
      <h2>Текущие заказы</h2>
      {orders.length > 0 ? (
        orders.map((order, index) => (
          <div key={index} className="orders-container">
            <h3>Заказ № {order.number_order}</h3>
            <h3>Статус заказа: {order.status}</h3>
            <h3>Дата оформления: {formatDate(order.payment_date)}</h3>
            <h3>Тип оплаты: {formatPaymentType(order.payment_type)}</h3>
            {order.products
              ?.filter(item => item.name !== 'Неизвестный товар')
              .map((item, idx) => (
                <div key={idx} className="order-item">
                  <img className="order-item-image" src={`https://novosibirsk.grilnica.ru/cdn/${item.imageFileName || 'placeholder.jpg'}`} alt={item.name || 'Товар не найден'} />
                  <div className="order-item-details">
                    <div className="order-item-name">{item.name || 'Неизвестный товар'}</div>
                    <div className="order-item-price">{item.totalPrice} руб.</div>
                    <ul className="order-item-modifiers">
                      {item.modifiers?.map((modifier, modIdx) => (
                        <li key={modIdx} className="modifier">
                          {modifier.restrictions?.isDefault ? '+' : '-'} {modifier.name || 'Неизвестный модификатор'}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            {order.combos
              ?.filter(combo => combo.name !== 'Неизвестное комбо')
              .map((combo, idx) => (
                <div key={idx} className="order-item">
                  <img className="order-item-image" src={`https://novosibirsk.grilnica.ru/cdn/${combo.imageFileName || 'placeholder.jpg'}`} alt={combo.name || 'Комбо не найдено'} />
                  <div className="order-item-details">
                    <div className="order-item-name">{combo.name || 'Неизвестное комбо'}</div>
                    <div className="order-item-price">{combo.price} руб.</div>
                  </div>
                </div>
              ))}
            <p>Сумма заказа: {calculateOrderTotal(order)} руб.</p>
          </div>
        ))
      ) : (
        <p>Нет текущих заказов</p>
      )}
      {isHistoryAvailable && !showHistory && (
        <button className="history-button" onClick={fetchOrderHistory}>
          Показать историю заказов
        </button>
      )}
      {showHistory && (
        <div className="order-history">
          <h2>История заказов</h2>
          {orderHistory.length > 0 ? (
            orderHistory.map((order, index) => (
              <div key={index} className="orders-container">
                <h3>Заказ № {order.number_order}</h3>
                <h3>Статус заказа: {order.status}</h3>
                <h3>Дата оформления: {formatDate(order.payment_date)}</h3>
                <h3>Тип оплаты: {formatPaymentType(order.payment_type)}</h3>
                {order.products?.map((item, idx) => (
                  <div key={idx} className="order-item">
                    <img
                      className="order-item-image"
                      src={`https://novosibirsk.grilnica.ru/cdn/${item.imageFileName || 'placeholder.jpg'}`}
                      alt={item.name || 'Товар не найден'}
                    />
                    <div className="order-item-details">
                      <div className="order-item-name">{item.name || 'Неизвестный товар'}</div>
                      <div className="order-item-price">{item.totalPrice} руб.</div>
                      <ul className="order-item-modifiers">
                        {item.modifiers?.map((modifier, modIdx) => (
                          <li key={modIdx} className="modifier">
                            {modifier.restrictions?.isDefault ? '+' : '-'} {modifier.name || 'Неизвестный модификатор'}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
                {order.combos?.map((combo, idx) => (
                  <div key={idx} className="order-item">
                    <img
                      className="order-item-image"
                      src={`https://novosibirsk.grilnica.ru/cdn/${combo.imageFileName || 'placeholder.jpg'}`}
                      alt={combo.name || 'Комбо не найдено'}
                    />
                    <div className="order-item-details">
                      <div className="order-item-name">{combo.name || 'Неизвестное комбо'}</div>
                      <div className="order-item-price">{combo.price} руб.</div>
                    </div>
                  </div>
                ))}
                <p>Сумма заказа: {calculateOrderTotal(order)} руб.</p>
                {order.rating ? (
                  <div className="order-rating">
                    {'⭐'.repeat(order.rating)}
                    {'☆'.repeat(5 - order.rating)}
                    {order.rating_comment && <p className="rating-comment">{order.rating_comment}</p>}
                  </div>
                ) : (
                  <button className="evaluate-button" onClick={() => openRatingModal(order)}>
                    Оценить заказ
                  </button>
                )}
              </div>
            ))
          ) : (
            <p className="no-history-message">У вас нет истории заказов.</p>
          )}
        </div>
      )}
      {isRatingModalOpen && (
        <div className="modal-overlay" onClick={closeRatingModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>Оцените заказ</h2>
            <div>
              {[1, 2, 3, 4, 5].map((star) => (
                <span
                  key={star}
                  onClick={() => setRating(star)}
                  className={star <= rating ? 'star star-filled' : 'star star-empty'}
                >
                  {star <= rating ? '⭐' : '☆'}
                </span>
              ))}
            </div>
            <textarea
              placeholder="Оставьте комментарий"
              value={ratingComment}
              onChange={(e) => setRatingComment(e.target.value)}
              rows={4}
              onClick={(e) => e.stopPropagation()}
            />
            <button onClick={submitRating}>Принять</button>
            <button onClick={closeRatingModal}>Отмена</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Orders;
